.session-icon {
    text-align: center;
    padding-top: 16px;
}

.session-icon ion-icon {
    width: 120px;
    height: 120px;
}

.speaker-image {
    display: block;
    max-width: 120px;
    margin: 16px auto;
    border-radius: 120px;
}

@media (prefers-color-scheme: dark) {
    .session-detail-card ion-note:not(.ion-color) {
        color: var(--ion-color-step-600);
    }
}
